import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import Config from './Config.json';
import galeProfilePic from './assets/img/gale_profile_1.jpg';
import robotProfilePic from './assets/img/cute_colorful_robot.jpg';

const WS_URL = 'wss://6aiolymuhf.execute-api.us-east-2.amazonaws.com/prod';

const App = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [pageHeader, setPageHeader] = useState(null);
    const [pageTitle, setPageTitle] = useState(null);
    const [serverMessage, setServerMessage] = useState('');
    const [showProgress, setShowProgress] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(true);

    const messagesEndRef = useRef(null);
    const textareaRef = useRef(null);
    const { topic } = useParams();

    if (!localStorage.getItem('user_id') || !localStorage.getItem('user_id').includes(topic)) {
        localStorage.setItem(
            'user_id', 
            `${topic}-${Intl.DateTimeFormat().resolvedOptions().timeZone}-${window.screen.width}x${window.screen.height}-${Math.floor(Math.random() * 10000) + 1}`
        );
    }

    let userId = localStorage.getItem('user_id');
    
    const { getWebSocket } = useWebSocket(WS_URL + '?userId=' + userId, {
        onOpen: () => {
            setSubmitDisabled(false);
            console.log('Connected as ' + userId);
        },
        onMessage: (event) => {
            const message = JSON.parse(event.data).message;
            setServerMessage(message);
            console.log('WS Message: ' + message);
            const chatResponseObject = JSON.parse(message);
            if (chatResponseObject.next_response) {
                setMessages([
                    ...messages.slice(0, messages.length - 1),
                    {
                        id: Date.now(),
                        text: chatResponseObject.next_response,
                        sender: 'Gale',
                        avatar: galeProfilePic
                    }
                ]);
            }
        },
        shouldReconnect: (closeEvent) => true
    });

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const createInitialMessage = () => {
        let initialMessage = ''

        switch (topic) {
            case 'thebusinessshowmarch2024':
                document.title = 'The Business Show 2024';
                setPageHeader('Gale Force AI at The Business Show March 2024');
                initialMessage = "It's great that we both got to attend The Business Show in Miami! " + 
                                 "I understand that you are interested in our Event Special Offer to use Master Closer AI for 30 days. " + 
                                 "Can I get your name and the name of your company? Type in the textbox below, and let's start the conversation.";
                break;
            case 'promptengineeringmastery':
                document.title = 'Prompt Engineering Mastery';
                setPageHeader('Prompt Engineering Mastery with Gale Force AI');
                initialMessage = "Thank you for attending the Prompt Engineering mastery class at the The Business Show March 2024! " + 
                                 "Can I get your name and the name of your company? Type in the textbox below, and let's start the conversation."
                break;
            default:
                document.title = 'Gale Force AI Chat';
                setPageHeader('Gale Force AI Chat');
                initialMessage = "It's so nice to meet you. How can I be of service today?"
                break;
        }

        const systemMessage = {
            id: userId,
            text: 'User ID',
            sender: 'System'
        };
        const message = {
            id: Date.now(),
            text: initialMessage,
            sender: 'Gale',
            avatar: galeProfilePic
        };
        setMessages([systemMessage, message]);
    };

    const fetchIPAddress = () => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                console.log(data.ip);
            })
            .catch(error => console.error('Error fetching IP:', error));
    }

    const handleSendMessage = () => {
        if (!newMessage.trim()) return; // Ignore empty messages
        
        const updatedMessages = [
            ...messages,
            {
                id: Date.now(),
                text: newMessage,
                sender: 'You',
                avatar: robotProfilePic
            },
            {
                id: Date.now() + 1,
                text: 'Gale is typing...',
                sender: 'Gale',
                avatar: galeProfilePic
            }
        ];
        console.log(updatedMessages);        
        setMessages(updatedMessages);
        setNewMessage('');
        
        fetch('https://3p5i2ef2f3.execute-api.us-east-2.amazonaws.com/aichatbotchatrequest', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': Config.aichatbotchatrequest,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedMessages)
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
                setShowProgress(false);
            })
            .catch(function (err) {
                console.log('Error:', err);
            });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        createInitialMessage();
        textareaRef.current.focus();
    }, []);

    return (
        <div 
            // className="responsive-div"
            style={{ 
                backgroundColor: '#121212', 
                color: 'white', 
                display: 'flex', 
                flexDirection: 'column', 
                
                justifyContent: 'space-between', // This will push your message container and form to opposite ends
                // height: '100vh',
                minHeight: '93.0vh', // or try `height: 100%` to make it flexible
                // minHeight: '100%',

                alignItems: 'center', // This will center everything horizontally
                padding: '20px' // Add padding to the entire container if needed
            }}
            >

            <>
                <style>
                    {`
                        body {
                            background-color: #121212; /* This sets the background color for the entire page */
                        }
                        .custom-scrollbar::-webkit-scrollbar {
                        width: 8px;
                        }
                        .custom-scrollbar::-webkit-scrollbar-track {
                        background: #121212;
                        }
                        .custom-scrollbar::-webkit-scrollbar-thumb {
                        background: #525252;
                        border-radius: 4px;
                        }
                        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
                        background: #787878;
                        }
                        /* Add styles for other browsers if necessary */

                        // @media (max-width: 768px) {
                        //     .responsive-div {
                        //       width: 100%;
                        //     }
                        // }
                    
                        // .responsive-div {
                        //     width: 50%; /* default width */
                        //     // display: flex;
                        //     align-items: center;
                        //     margin-bottom: 20px;
                        // }

                        .responsive-div {
                            width: 50%; /* default width */
                            align-items: center;
                            // margin-bottom: 20px;
                        }
                          
                        @media (max-width: 768px) {
                            .responsive-div {
                              width: 100%;
                            }
                        }
                    `}
                </style>

                <div 
                    className="custom-scrollbar responsive-div" 
                    style={{ 
                        // width: '50%',
                        maxHeight: '100%', 
                        overflow: 'auto',
                        // paddingBottom: '1cm' // Increased padding-bottom to 1cm
                    }}
                    >

                    <h3 style={{ marginBottom: '35px' }}>
                        <b>{pageHeader}</b>
                    </h3>

                    {messages.filter(message => message.sender === 'Gale' || message.sender === 'You').map((message) => (
                        <div key={message.id} style={{ display: 'flex', alignItems: 'flex-start', margin: '10px 0' }}>
                            <img 
                                src={message.avatar} // Replace with your avatar URL
                                alt="Avatar"
                                style={{
                                    width: '40px', // Set the size of the avatar
                                    height: '40px', // Set the size of the avatar
                                    borderRadius: '50%', // Make the image circular
                                    objectFit: 'cover', // Ensure the image covers the area
                                    marginRight: '10px', // Add some space between the avatar and the message
                                }}
                            />
                            <div style={{ flex: 1, lineHeight: '1.6', whiteSpace: 'pre-wrap' }}>
                                <b>{message.sender}:</b><br />{message.text}
                            </div>
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                </div>
            </>

            <div className="responsive-div" style={{ display: 'flex', alignItems: 'center', marginBottom: '3px' }}> {/* Container for the input and button */}
                <textarea
                    ref={textareaRef}
                    value={newMessage}
                    maxLength='500'
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) { // Checks if Enter key is pressed without the Shift key
                            e.preventDefault(); // Prevents the default action to avoid a new line in the textarea
                            handleSendMessage(); // Calls the function to send the message
                        }
                    }}
                    style={{ 
                        flexGrow: 1,
                        boxSizing: 'border-box', // This ensures padding is included in the height calculation
                        height: '48px',
                        resize: 'none',
                        borderRadius: '18px 0 0 18px',
                        padding: '10px',
                        border: 'none',
                        marginRight: '-1px',
                        outline: 'none',
                        backgroundColor: '#525252',
                        color: 'white',
                        fontSize: '1rem',
                    }}
                    placeholder="Type your message here..."
                />
                <button 
                    onClick={handleSendMessage} 
                    style={{ 
                        flexGrow: 0, // Prevent the button from growing
                        boxSizing: 'border-box', // This ensures padding is included in the height calculation
                        height: '48px', // Slightly larger to cover the border of the textarea
                        borderRadius: '0 18px 18px 0', // Rounded corners on the right side only
                        border: 'none',
                        backgroundColor: '#4a4a4a',
                        color: 'white',
                        fontSize: '1rem',
                        padding: '0 20px',
                        marginLeft: '-1px', // Aligns seamlessly with the textarea
                        whiteSpace: 'nowrap', // Keeps the text within the button on one line
                    }}
                    >
                    Send
                </button>
            </div>
        </div>
    );
};

export default App;
