import React from 'react';
import { Navigate, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Gale from './Gale';

function App() {
    const RedirectToExternalUrl = ({ url }) => {
		window.location.href = url;
		return null;
	};

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Gale />} />
                <Route path="/:topic" element={<Gale />} />
                {/* <Route path="prompt-engineering-mastery" element={<Navigate to="/promptengineeringmastery" replace />} />
                <Route path="what-generative-ai-can-do-for-you" element={<Navigate to="/thebusinessshowmarch2024" replace />} /> */}
                <Route path="prompt-engineering-mastery" element={<RedirectToExternalUrl url="https://chat.galeforceai.com/promptengineeringmastery" />} />
                <Route path="what-generative-ai-can-do-for-you" element={<RedirectToExternalUrl url="https://chat.galeforceai.com/thebusinessshowmarch2024" />} />
            </Routes>
        </Router>
    );
}

export default App;